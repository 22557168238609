.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 7px;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin: 0px;
    cursor: pointer;
    padding: 0px !important;
}

.slider:hover  {
    opacity: 1;
  }
  
.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 7px;
    height: 7px;
    background: #ffffff;
    box-shadow: -1000px 0 0 1000px #ffffff;
    cursor: pointer;
    border: 0;
    border-radius: 0;
}
.slider::-webkit-slider-thumb:hover {
    width: 8px;
    height: 8px;
    transition: linear 0.2s;
}

.slider::-moz-range-thumb {
    width: 7px;
    height: 7px;
    cursor: pointer;
    border: 0;
    border-radius: 0;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .slider {
      overflow: hidden;
      -webkit-appearance: none;
      background-color: grey;
    }
    
    .slider::-webkit-slider-runnable-track {
      height: 7px;
      -webkit-appearance: none;
      color: #ffffff;
      margin-top: -1px;
    }
    
    .slider::-webkit-slider-thumb, .slider::-moz-range-thumb {
      width: 10px;
      -webkit-appearance: none;
      height: 10px;
      cursor: pointer;
      background: #ffffff;
      box-shadow: -100% 0 0 100% #ffffff;
      border: 0;
      border-radius: 0
    }

}

.slider::-moz-range-progress {
  background-color: #ffffff; 
  box-shadow: -1000px 0 0 1000px #ffffff;
}

.volume-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 7px;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin: 0px;
    cursor: pointer;
    padding: 0px !important;
}

.volume-slider:hover  {
    opacity: 1;
  }
  
.volume-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 7px;
    height: 7px;
    background: #ffffff;
    box-shadow: -1000px 0 0 1000px #ffffff;
    cursor: pointer;
    border: 0;
    border-radius: 0;
}
.volume-slider::-webkit-slider-thumb:hover {
    width: 8px;
    height: 8px;
    transition: linear 0.2s;
}

.volume-slider::-moz-range-thumb {
    width: 7px;
    height: 7px;
    cursor: pointer;
    border: 0;
    border-radius: 0;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .volume-slider {
      overflow: hidden;
      -webkit-appearance: none;
      background-color: grey;
    }
    
    .volume-slider::-webkit-slider-runnable-track {
      height: 7px;
      -webkit-appearance: none;
      color: #ffffff;
      margin-top: -1px;
    }
    
    .volume-slider::-webkit-slider-thumb, .volume-slider::-moz-range-thumb {
      width: 10px;
      -webkit-appearance: none;
      height: 10px;
      cursor: pointer;
      background: #ffffff;
      box-shadow: -100% 0 0 100% #ffffff;
      border: 0;
      border-radius: 0
    }

}

.volume-slider::-moz-range-progress {
  background-color: #ffffff; 
  box-shadow: -1000px 0 0 1000px #ffffff;
}

#speaker-div > input[type=range] {
  width: 0px;
  opacity: 0;
  transition: width 150ms ease-in-out, opacity 120ms;
}

#speaker-div:hover > input[type=range] {
  opacity: 1;
  width: 100%;
}


.seeker-container {
  position: relative;
  flex: 20;
  color:  white;
  margin: 0 5px;
}

.seeker-container >  span {
  display: none;
  position: absolute;
  top: -20px;
  padding: 2px 5px;
  background: rgba(0, 0, 0, .4);
  animation: fade-in 300ms;
}

.seeker:hover + span {
  display: block;
}

.seeker-container:hover > span:hover {
  display: none;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#summary-togglebar { 
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  height: 15%;
  width: 0;
  border-right: 18px solid #35599c;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  cursor: pointer;
  z-index: 3;
  transition: border-right 200ms;
}

#summary-togglebar:active {
  border-right: 17px solid #35599c;
}

#summary-togglebar > div {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}


@media only screen and (orientation:portrait) {
  #summary-togglebar, #fullscreen-btn, .appearance {
    display: none;
  }
}

@media only screen and (max-width:  1000px)
{
  .appearance {
    display: none;
  }
}

@media only screen and (max-width: 200px) 
{
  #player-logo {
    display: none;
  }
}

.settings-container > hr {
  width: 90%;
}

.toolTipText {
  display: none;
  position: absolute;
  width: max-content;
  bottom: 100%;
  font-size: .8em;
  padding: 3px 5px;
  border-radius: 3px;
  color: white;
  background: rgba(0, 0, 0, .4);
  animation: tooltipFadeAnimation 1s;
}

@keyframes tooltipFadeAnimation {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


#settings-icon {
  position:  relative;
  enableBackground: new 0 0 512 512;
  height: 1rem;
  width: 1rem;
  cursor: pointer;
}