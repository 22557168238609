.duration-input {
	margin-left: 5px !important;
	padding: 5px 10px !important;
	color: #333 !important;
	border: none !important;
	outline: none !important;
	border-radius: 5px !important;
}

.duration-input-button-wrapper {
	display: none !important;
}