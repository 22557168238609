.App {
  text-align: center;
  height: 100vh;
  /*width: 100vw;*/
}
.select-nothing {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-drag: none;
  cursor: default;
}

::-webkit-scrollbar {
  opacity: 0;
}